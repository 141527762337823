<template>
  <div>

    <v-card rounded elevation="1" class="mb-2">
     <v-card-text>
       <v-row class="yellow darken-2 rounded text-center mt-n6">
         <v-col cols="12" >
           <div class="subtitle-1 blue--text text--darken-2 font-weight-black text-uppercase ma-n2">
             {{itemModified.description}}
           </div>
         </v-col>
       </v-row>

       <v-row no-gutters class="mt-4">
         <v-col cols="6" sm="6" md="6" class="mt-1">
           <v-flex class="text-center">
             <v-avatar :color="countPeluang? 'yellow darken-1': 'grey lighten-2'" :class="countPeluang?'elevation-8 ':'elevation-0'" ><v-icon dark>mdi-tag</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{ countPeluang }}</div>
               <div class="caption">{{$t('peluangPotensi')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1">
           <v-flex class="text-center"  >
             <v-avatar :color="countKi? 'yellow darken-1': 'grey lighten-2'" :class="countKi?'elevation-8':'elevation-0'" ><v-icon dark>mdi-factory</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countKi}}</div>
               <div class="caption">{{$t('kawasanIndustri')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1">
           <v-flex class="text-center">
             <v-avatar :color="countKih? 'green darken-2': 'grey lighten-2'" :class="countKih?'elevation-8':'elevation-0'" ><v-icon dark>mdi-factory</v-icon></v-avatar>
             <div class="small-line-height mt-1">
                <div class="font-weight-bold">{{countKih}}</div>
                <div class="caption">{{$t('kawasanIndustriHalal')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1">
           <v-flex class="text-center">
<!--             <v-avatar :color="countKpi? 'yellow darken-2': 'grey lighten-2'" :class="countKpi?'elevation-8':'elevation-0'" ><v-icon dark>mdi-factory</v-icon></v-avatar>-->
             <v-avatar color="blue lighten-1" class="elevation-8" ><v-icon dark>mdi-factory</v-icon></v-avatar>
             <div class="small-line-height mt-1">
<!--               <div class="font-weight-bold">{{countKpi}}</div>-->
               <div class="caption">{{$t('kawasanPeruntukanIndustri')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1">
           <v-flex class="text-center">
             <v-avatar :color="countKek? 'primary': 'grey lighten-2'" :class="countKek?'elevation-8':'elevation-0'" ><v-icon dark>mdi-factory</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countKek}}</div>
               <div class="caption">{{$t('kawasanEkonomiKhusus')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
             <v-avatar :color="countBandara? 'orange darken-4': 'grey lighten-2'"  :class="countBandara?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickBandara" ><v-icon dark>mdi-airplane</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countBandara}}</div>
               <div class="caption">{{$t('bandara')}}</div>
             </div>
           </v-flex>
         </v-col>

         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
             <v-avatar :color="countPelabuhan? 'blue lighten-2': 'grey lighten-2'" :class="countPelabuhan?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickPelabuhan" ><v-icon dark>mdi-ferry</v-icon></v-avatar>
             <div class="small-line-height mt-1">
                <div class="font-weight-bold">{{countPelabuhan}}</div>
                <div class="caption">{{$t('pelabuhan')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
             <v-avatar :color="countSaranaPendidikan? 'teal lighten-1': 'grey lighten-2'" :class="countSaranaPendidikan?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickPendidikan"  ><v-icon dark>mdi-tshirt-v</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countSaranaPendidikan}}</div>
               <div class="caption">{{$t('saranaPendidikan')}}</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
             <v-avatar :color="countHotel? 'pink darken-2': 'grey lighten-2'" :class="countHotel?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickHotel" ><v-icon dark>mdi-bed</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countHotel}}</div>
               <div class="caption">Hotel</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
               <v-avatar :color="countRumahSakit? 'red': 'grey lighten-2'" :class="countRumahSakit?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickRumahSakit"  ><v-icon dark>mdi-hospital-building</v-icon></v-avatar>
               <div class="small-line-height mt-1">
                 <div class="font-weight-bold">{{countRumahSakit}}</div>
                 <div class="caption">{{$t('rumahSakit')}}</div>
               </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
             <v-avatar :color="countWisata? 'blue lighten-2': 'grey lighten-2'" :class="countWisata?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickWisata"  ><v-icon dark>mdi-beach</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countWisata}}</div>
               <div class="caption">Wisata</div>
             </div>
           </v-flex>
         </v-col>
         <v-col cols="6" sm="6" md="6" class="mt-1" v-if="false">
           <v-flex class="text-center">
             <v-avatar :color="countPasar? 'success': 'grey lighten-2'" :class="countPasar?'elevation-8 icon icon--instagram':'elevation-0'" @click="clickPasar" ><v-icon dark>mdi-cart</v-icon></v-avatar>
             <div class="small-line-height mt-1">
               <div class="font-weight-bold">{{countPasar}}</div>
               <div class="caption">Pasar</div>
             </div>
           </v-flex>
         </v-col>
       </v-row>

       <v-divider></v-divider>
       <v-row no-gutters class="mt-4">
         <v-col cols="6" sm="6" md="6" class="mt-1" v-for="(item, index) in computedItemsFInfrastructureType.filter(x => x.kawasan==false && x.statusActive==true)" :key="index">
           <v-flex class="text-center">

             <v-hover>
               <template v-slot:default="{ hover }">
                 <div v-if="lookupTotalInfrastruktur(item)>0">
                   <v-avatar class="elevation-10 enable-image" :class="{'hover-image ': hover}" @click="clickInfrastructure(item)" >
                     <v-img :src="lookupImageUrlLazy(item)" ></v-img>
                   </v-avatar>
                 </div>
                 <div v-else>
                   <v-avatar class="disable-image"  @click="clickInfrastructure(item)" >
                     <v-img :src="lookupImageUrlLazy(item)"></v-img>
                   </v-avatar>
                 </div>

               </template>
             </v-hover>

             <div class="small-line-height mt-1">
               <div :class="lookupTotalInfrastruktur(item)>0? 'font-weight-bold': 'caption font-weight-light'">{{ lookupTotalInfrastruktur(item) }}</div>
               <div :class="lookupTotalInfrastruktur(item)>0? '': 'caption font-weight-light'">
                 {{ lang==='id'? item.description : item.descriptionEn }}
               </div>
             </div>
           </v-flex>
         </v-col>
       </v-row>
     </v-card-text>
   </v-card>

    <v-card rounded elevation="1"  class="mb-4">
      <v-card-text>
        <v-row class="yellow darken-2 rounded text-center mt-n6">
          <v-col cols="12" >
            <div class="subtitle-2 white--text text--darken-2 font-weight-black text-uppercase ma-n2">
              {{$t('dayaDukung')}}
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-col cols="6" sm="6" md="6" class="mt-1">
            <v-flex class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar color="blue"
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-8 icon icon--instagram" @click="pdrbDialogShow"><v-icon dark color="yellow lighten-4">mdi-cube</v-icon></v-avatar>
                </template>
                <span>{{$t('klikUntukMelihatGrafikDanDetail')}}</span>
              </v-tooltip>
              <div class="small-line-height mt-1">
                <div class="caption">PDRB</div>
                <div class="font-weight-bold caption">{{ lookupPdrbTotal() }}</div>
              </div>
            </v-flex>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="mt-2">
            <v-flex class="text-center">
              <v-avatar color="blue" class="elevation-8 icon icon--instagram" ><v-icon dark>mdi-cash-100</v-icon></v-avatar>
              <div class="small-line-height mt-1">
                <div class="caption">UMK</div>
                <div class="font-weight-bold caption">{{ lookupUmk() }}</div>
              </div>
            </v-flex>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="mt-2">
            <v-flex class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar color="blue"
                            v-bind="attrs"
                            v-on="on"
                            class="elevation-8 icon icon--instagram"  @click="realisasiDialogShow"><v-icon dark>mdi-cash-multiple</v-icon></v-avatar>
                </template>
                <span>{{$t('klikUntukMelihatGrafikDanDetail')}}</span>
              </v-tooltip>
              <div class="small-line-height mt-1">
                <div class="caption">{{$t('realisasiInvestasi')}}</div>
                <div class="font-weight-bold caption">{{ lookupRealisasi() }}</div>
              </div>
            </v-flex>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="mt-2">
            <v-flex class="text-center">
              <v-avatar color="blue" class="elevation-8 icon icon--instagram" ><v-icon dark color="yellow lighten-2">mdi-human-male-female</v-icon></v-avatar>
              <div class="small-line-height mt-1">
                <div class="caption">{{$t('penduduk')}}</div>
                <div class="font-weight-bold caption">{{ lookupPenduduk() }}</div>
              </div>
            </v-flex>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="mt-2" v-if="false">
            <v-flex class="text-center">
              <v-avatar color="blue" class="elevation-8 icon icon--instagram" ><v-icon dark color="teal lighten-5">mdi-shopping</v-icon></v-avatar>
              <div class="small-line-height mt-1">
                <div class="caption">UMKM Siap Bermitra</div>
                <div class="font-weight-bold caption"></div>
              </div>
            </v-flex>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <ProfilDaerahDetilPdrbDialog
        :itemModified="itemModified"
        :pdrbTitle="pdrbTitle"
        ref="refProfilDaerahDetilPdrbDialog"
    ></ProfilDaerahDetilPdrbDialog>
    <ProfilDaerahDetilRealisasiDialog
        :itemModified="itemModified"
        :realisasiTitle="realisasiTitle"
        ref="refProfilDaerahDetilRealisasiDialog"
    ></ProfilDaerahDetilRealisasiDialog>

  </div>
</template>

<script>

import FPotensiService from "@/services/apiservices/f-potensi-service";
import EPotensiType from "@/models/e-potensi-type";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";
import FDayadukungService from "@/services/apiservices/f-dayadukung-service";
import FDayadukungTypeService from "@/services/apiservices/f-dayadukung-type-service";
import ProfilDaerahDetilPdrbDialog from "@/components/public_daerah_detil/ProfilDaerahDetilPdrbDialog";
import ProfilDaerahDetilRealisasiDialog from "@/components/public_daerah_detil/ProfilDaerahDetilRealisasiDialog";
import FileService from "@/services/apiservices/file-service";

export default {
  name: "ProfilDaerahDetilSummary",
  components: {ProfilDaerahDetilRealisasiDialog, ProfilDaerahDetilPdrbDialog},
  props:{
    itemModified: Object
  },
  data() {
    return {

      transparent: 'rgba(255, 255, 255, 0)',

      countPeluang: 0,
      countKi:0,
      countKih: 0,
      countKpi: 0,
      countKek: 0,
      countBandara: 0,
      countPelabuhan: 0,
      countSaranaPendidikan: 0,
      countHotel: 0,
      countRumahSakit: 0,
      countWisata: 0,
      countPasar: 0,
    }
  },
  watch:{
    itemModified:{
      handler: function (value){
        if (value.id>0){

          this.refreshComponent()

        }
      }
    }
  },
  computed:{
    pdrbTitle(){
      return this.lookupPdrbTotal()
    },
    realisasiTitle(){
      return this.lookupRealisasiTotal()
    },

    computedItemsFInfrastructureType(){
      return this.$store.state.potensi.itemsFInfrastructureType
    },
    computedItemsFInfrastructure(){
      return this.$store.state.potensi.itemsFInfrastructure
    },

    computedBandaraShow:{
      get(){
        return this.$store.state.potensi.bandaraShow
      },
      set(value){
        this.$store.dispatch("potensi/updateBandaraShow", value)
      }
    },
    computedPelabuhanShow:{
      get(){
        return this.$store.state.potensi.pelabuhanShow
      },
      set(value){
        this.$store.dispatch("potensi/updatePelabuhanShow", value)
      }
    },
    computedPendidikanShow:{
      get(){
        return this.$store.state.potensi.pendidikanShow
      },
      set(value){
        this.$store.dispatch("potensi/updatePendidikanShow", value)
      }
    },
    computedHotelShow: {
      get(){
        return this.$store.state.potensi.hotelShow
      },
      set(value){
        this.$store.dispatch("potensi/updateHotelShow", value)
      }
    },
    computedRumahSakitShow:{
      get(){
        return this.$store.state.potensi.rumahSakitShow
      },
      set(value){
        this.$store.dispatch("potensi/updateRumahSakitShow", value)
      }
    },
    computedWisataShow:{
      get(){
        return this.$store.state.potensi.wisataShow
      },
      set(value){
        this.$store.dispatch("potensi/updateWisataShow", value)
      }
    },
    computedPasarShow:{
      get(){
        return this.$store.state.potensi.pasarShow
      },
      set(value){
        this.$store.dispatch("potensi/updatePasarShow", value)
      }
    },
    computedItemsDayaDukungType() {
      return this.$store.state.potensi.itemsFDayaDukungType
    },
    computedItemsDayaDukung() {
      return this.$store.state.potensi.itemsFDayaDukung
    }

  },
  methods:{
    refreshComponent(){
      this.countPeluang= 0,
      this.countKi= 0,
      this.countKih= 0,
      this.countKpi= 0,
      this.countKek= 0,

      this.countBandara= 0,
      this.countPelabuhan= 0,
      this.countSaranaPendidikan= 0,
      this.countHotel= 0,
      this.countRumahSakit= 0,
      this.countWisata= 0,
      this.countPasar= 0

      FPotensiService.getAllFPotensiContainingPublicWithCity(1, 9999, "created", "DESC", '', this.itemModified.description ).then(
          response => {


            const { items} = response.data
            // this.fPotensies = items
            this.$store.dispatch('potensi/updateItemsFPotensi', items)


            // console.log(JSON.stringify(items))
            // console.log(items.length)

            items.forEach( item => {
              if (item.jenis.includes(EPotensiType.PELUANG) || item.jenis.includes(EPotensiType.IPRO) || item.jenis.includes(EPotensiType.POTENSI) ){
                this.countPeluang++;
              }
              if (item.jenis.includes(EPotensiType.KI)){
                this.countKi++;
              }
              if (item.jenis.includes(EPotensiType.KIH)){
                this.countKih++;
              }
              if (item.jenis.includes(EPotensiType.KPI)){
                this.countKpi++;
              }
              if (item.jenis.includes(EPotensiType.KEK)){
                this.countKek++;
              }

            })
          },
          error => {
            console.log(error.response)
          }
      )

      FInfrastructureService.getAllFInfrastructureByCityPublic(this.itemModified.description).then(
          response => {
            // console.log(JSON.stringify(response.data))

            const items = response.data
            // this.$store.dispatch("potensi/updateItemsFInfrastructure", items)

            items.forEach( item =>{
              const finfrastructureType = this.lookupFInfratructureType(item.finfrastructureTypeBean)
              // console.log(JSON.stringify(finfrastructureType))

              if (finfrastructureType.description.toUpperCase().includes("BANDARA")){
                this.countBandara++
              }
              if (finfrastructureType.description.toUpperCase().includes("PELABUHAN")){
                this.countPelabuhan++
              }
              if (finfrastructureType.description.toUpperCase().includes("PENDIDIKAN")){
                this.countSaranaPendidikan++
              }
              if (finfrastructureType.description.toUpperCase().includes("HOTEL")){
                this.countHotel++
              }
              if (finfrastructureType.description.toUpperCase().includes("RUMAH SAKIT")){
                this.countRumahSakit++
              }
              if (finfrastructureType.description.toUpperCase().includes("WISATA")){
                this.countWisata++
              }
              if (finfrastructureType.description.toUpperCase().includes("PASAR")){
                this.countPasar++
              }

            })
          }
      )
      FDayadukungTypeService.getAllFDayaDukungTypePublic().then(
          response => {
            this.$store.dispatch("potensi/updateItemsFDayaDukungType", response.data)
          },
          error =>{
            console.log(error)
          }
      )
      FDayadukungService.getAllFDayaDukungByCityPublic(this.itemModified.description).then(
          response => {
            this.$store.dispatch("potensi/updateItemsFDayaDukung", response.data)
          },
          error =>{
            console.log(error)
          }
      )
    },
    clickInfrastructure(finfrastructureTypeBean){
      // console.log(finfrastructureTypeBean)
      const items = this.$store.state.potensi.itemsFInfrastructure.filter(x => x.finfrastructureTypeBean == finfrastructureTypeBean.id)
      this.$store.dispatch("potensi/updateItemsFInfrastructureFiltered", items)
    },

    clickBandara(){
      this.computedBandaraShow = ! this.computedBandaraShow
    },
    clickPelabuhan(){
      this.computedPelabuhanShow = ! this.computedPelabuhanShow
    },
    clickPendidikan(){
      this.computedPendidikanShow = ! this.computedPendidikanShow

    },
    clickHotel(){
      this.computedHotelShow = ! this.computedHotelShow

    },
    clickRumahSakit(){
      this.computedRumahSakitShow = ! this.computedRumahSakitShow
    },
    clickWisata(){
      this.computedWisataShow = ! this.computedWisataShow
    },
    clickPasar(){
      this.computedPasarShow = ! this.computedPasarShow
    },

    pdrbDialogShow(){
      this.$refs.refProfilDaerahDetilPdrbDialog.showDialog()
    },
    realisasiDialogShow(){
      this.$refs.refProfilDaerahDetilRealisasiDialog.showDialog()
    },

    lookupFInfratructureType (finfratructureTypeBean) {
      const str = this.computedItemsFInfrastructureType.filter(x => x.id===finfratructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFDayaDukungTypeByDesc (desc) {
      const str = this.computedItemsDayaDukungType.filter(x => x.description.toUpperCase().includes(desc.trim().toUpperCase()))
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupPdrbTotal(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("PDRB")

      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.tahun < b.tahun) ? 1: -1 )
      if (list.length >0) {
        let sum = 0
        list.forEach(item =>{
              if (! item.description.trim().toUpperCase().includes("PRODUK DOMESTIK REGIONAL BRUTO")){
                sum += item.nilai1
              }
          }
        )
        return `Rp ${ this.formatNumberString(sum) }`
      }else {
        return "-"
      }
    },
    lookupRealisasiTotal(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("Realisasi")

      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.tahun < b.tahun) ? 1: -1 )
      if (list.length >0) {
        let sum = 0
        list.forEach(item =>{
              sum += item.nilai1
            }
        )
        return `Rp ${ this.formatNumberString(sum) }`
      }else {
        return "-"
      }
    },

    lookupRealisasi(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("Realisasi")
      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.tahun < b.tahun) ? 1: -1 )
      if (list.length >0) {
        let sum = 0
        list.forEach(item =>
          sum += item.nilai1
        )
        return `Rp ${ this.formatNumberString(sum) }`
      }else {
        return "-"
      }
    },

    lookupTotalInfrastruktur(finfrastructureTypeBean){
      // console.log(finfrastructureTypeBean.id)
      // finfrastructureTypeBean.toString()
      // console.log(JSON.stringify(this.computedItemsFInfrastructure))

      return this.computedItemsFInfrastructure.filter(x => x.finfrastructureTypeBean==finfrastructureTypeBean.id).length
    },

    lookupUmk(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("UMK")
      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.tahun < b.tahun) ? 1: -1 )
      if (list.length >0) {
        return `Rp ${ this.formatNumberString(list[0].nilai1) }`
      }else {
        return "-"
      }
    },
    lookupPenduduk(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("Penduduk")
      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.tahun < b.tahun) ? 1: -1 )
      if (list.length >0) {
        return `${ this.formatNumberString(list[0].nilai1) } jiwa`
      }else {
        return "-"
      }
    },

    formatNumberString(value){
      let valueStr = ""
      if (value/1000000000000 >1) {
        valueStr = `${parseFloat(value / 1000000000000).toFixed(2) } Triliun`
      }else if(value/1000000000>1){
        valueStr = `${parseFloat(value / 1000000000).toFixed(2) } Miliar`
      }else if(value/1000000>1){
        valueStr = `${parseFloat(value / 1000000).toFixed(2) } Juta`
      }else if(value/1000>1){
        valueStr = `${parseFloat(value / 1000).toFixed(2) } Ribu`
      }else{
        valueStr = value.toString()
      }
      return valueStr
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },

    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  }
}
</script>

<style scoped>
  .small-line-height{
    line-height: 0.9em;
  }



  .icon {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 26px;
    color: #fff;
    border-radius: 50% ;
    transition-property:
        outline-offset, outline-color,
        background-color;
    transition-duration: .25s;
  }

  .icon:hover {
    outline-offset: 4px;
  }
  .icon:hover {
    outline-offset: 4px;
  }
  .icon:hover i {
    animation: shake .25s;
  }
  .icon--instagram:hover {
    background-image: radial-gradient(circle at 30% 107%,
    #fdf497 0%, #fdf497 5%,
    #fd5949 45%, #d6249f 60%,
    #285AEB 90%);
    outline-color: #a02d76;
  }
  @keyframes shake {
    10% {
      transform: rotate(15deg);
    }

    20% {
      transform: rotate(-15deg);
    }

    30% {
      transform: rotate(15deg);
    }

    40% {
      transform: rotate(-15deg);
    }
  }

  @keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
  }

  .hover-image{
    opacity: 0.7;
    cursor:pointer;
  }
  .disable-image{
    opacity: 0.4;
  }
  .enable-image{
    cursor:pointer;
  }


</style>