<template>
  <v-dialog
      v-model="dialogShow"
      max-width="1000"
      hide-overlay
  >
        <v-card elevation="10">
          <v-card-title>
            <v-row class="yellow darken-2 rounded text-center">
              <v-col cols="12" >
                <div class="subtitle-1 blue--text text--darken-2 font-weight-black text-uppercase ma-n2">
                  PDRB {{itemModified.description}}
                </div>
                <div class="subtitle-1 font-weight-black">
<!--                  Total {{pdrbTitle}} <span class="caption ml-2"> *Sumber BPS {{itemModified.description}}</span>-->
                      Total {{pdrbTitle}} <span class="caption ml-2"> *{{$t('bps')}}/BKPM RI</span>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <MyChartDonut></MyChartDonut>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
  </v-dialog>

</template>

<script>

import MyChartDonut from "@/components/public_daerah_detil/PdrbChartDonut";
export default {
  name: 'ProfilDaerahDetilPdrbDialog',
  components: {
    MyChartDonut
  },
  props:{
    formMode: String,
    itemModified: Object,
    itemsFSectorType: [],
    pdrbTitle: String,
  },
  data() {
    return {
      title: '',

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },


      series1: [2, 4, 5, 6],
      labels1: ['a', 'b', 'c', 'd'],
    }
  },
  computed: {
    computedItemsDayaDukungType() {
      return this.$store.state.potensi.itemsFDayaDukungType
    },
    computedItemsDayaDukung() {
      return this.$store.state.potensi.itemsFDayaDukung
    },

  },
  watch: {
  },

  methods: {

    showDialog() {
      this.dialogShow = !this.dialogShow
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    setDialogState(value){
      this.dialogShow =value
    },
  }

}
</script>

<style scoped>


</style>