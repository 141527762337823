<template>
  <div>
    <v-row class="mx-2 mt-2">
      <v-col cols="12" sm="12" md="3" >
        <ProfilDaerahDetilSummary
          :itemModified="itemModified"
        ></ProfilDaerahDetilSummary>
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <v-flex>
          <ProfileDaerahDetilMap
              :itemModified="itemModified"
          ></ProfileDaerahDetilMap>
        </v-flex>
        <v-flex>
          <!--                 v-if="! computedKomoditiShow"-->
          <v-btn color="yellow darken-2"  class="rounded-lg mt-n6 ml-2 icon--instagram" elevation="6"
                 @click="computedKomoditiShow = !computedKomoditiShow"
          >
            {{$t('komoditi')}}
            <v-icon
              right
              dark
            >
              mdi-lightbulb-on-outline
            </v-icon>
          </v-btn>
          <ProfilDaerahDetilKomoditi
                    v-if="computedKomoditiShow"
              :itemModified="itemModified"
          ></ProfilDaerahDetilKomoditi>
        </v-flex>
        <v-flex>
          <ProfilDaerahDetilTable
              :itemModified="itemModified"
          ></ProfilDaerahDetilTable>
        </v-flex>
      </v-col>
    </v-row>

    <v-row v-if="false">
      <v-col sm="12" md="12" cols="12">
        <v-card>
          <v-card-title><v-btn depressed color="primary" elevation="10" class="mt-n8" @click="computedKomoditiShow = !computedKomoditiShow">{{$t('peluangInvestasi')}}</v-btn></v-card-title>
          <v-card-text class="mx-2">
            <ProfilDaerahDetilTable
                :itemModified="itemModified"
            ></ProfilDaerahDetilTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <PublicFooter></PublicFooter>
  </div>
</template>

<script>
import ProfileDaerahDetilMap from "@/components/public_daerah_detil/ProfilDaerahDetilMap";
import ProfilDaerahDetilSummary from "@/components/public_daerah_detil/ProfilDaerahDetilSummary";
import FAreaService from "@/services/apiservices/f-area-service";
import FArea from "@/models/f-area";
import ProfilDaerahDetilTable from "@/components/public_daerah_detil/ProfilDaerahDetilTable";
import FInfrastructureTypeService from "@/services/apiservices/f-infrastructure-type-service";
import PublicFooter from "@/components/PublicFooter";
import ProfilDaerahDetilKomoditi from "@/components/public_daerah_detil/ProfilDaerahDetilKomoditi";
import FKomoditiService from "@/services/apiservices/f-komoditi-service";
import FKomoditiTypeService from "@/services/apiservices/f-komoditi-type-service";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";
import FSatuanService from "@/services/apiservices/f-satuan-service";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";

export default {
  name: "ProfilDaerahDetil",
  components: {
    ProfilDaerahDetilKomoditi,
    PublicFooter,
    ProfilDaerahDetilTable,
    ProfilDaerahDetilSummary,
    ProfileDaerahDetilMap,
  },
  data(){
    return {
      itemModified: new FArea(),
    }
  },
  computed:{
    computedKomoditiShow:{
      get(){
        return this.$store.state.potensi.komoditiShow
      },
      set(value){
        this.$store.dispatch("potensi/updateKomoditiShow", value)
      }
    }

  },
  methods:{
    fetchParent(){
      FSatuanService.getAllFSatuanPublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSatuan', response.data)
            // console.log(JSON.stringify(response.data))
          },
          error => {
            console.log(error.response)
          }
      )

      FInfrastructureTypeService.getAllFInfrastructureTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFInfrastructureType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

      FKomoditiTypeService.getAllFKomoditiTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFKomoditiType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
    },
    fetchParentByArea() {
      FKomoditiService.getAllFKomoditiByCitySimplePublic(this.itemModified.description).then(
          response => {

            // console.log(JSON.stringify(response.data))

            this.$store.dispatch('potensi/updateItemsFKomoditi', response.data)
          },
          error => {
            console.log(error)
          }
      )

      this.$store.dispatch("potensi/updateItemsFInfrastructureFiltered", [])
      FInfrastructureService.getAllFInfrastructureByFareaPublic(this.itemModified.id).then(
          response => {

            // console.log(JSON.stringify(response.data))
            this.$store.dispatch('potensi/updateItemsFInfrastructure', response.data)
            // console.log(this.$store.state.potensi.itemsFInfrastructure.length)
          },
          error => {
            console.log(error)
          }
      )
      FSubAreaService.getAllFSubAreaByParentPublic(this.itemModified.id).then(
          response => {
            // console.log(response.data)
            this.$store.dispatch('potensi/updateItemsFSubArea', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
    }

  },
  mounted() {
    const paramId = this.$route.params.id

    this.fetchParent()

    FAreaService.getFAreaById(paramId).then(
        response =>{
          this.itemModified = response.data

          this.fetchParentByArea()

        }
    )

  }


}
</script>

<style scoped>

.icon {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 26px;
  color: #fff;
  border-radius: 50% ;
  transition-property:
      outline-offset, outline-color,
      background-color;
  transition-duration: .25s;
}

.icon:hover {
  outline-offset: 4px;
}
.icon:hover {
  outline-offset: 4px;
}

.icon:hover i {
  animation: shake .25s;
}

.icon--instagram:hover {
  background-image: radial-gradient(circle at 30% 107%,
  #fdf497 0%, #fdf497 5%,
  #fd5949 45%, #d6249f 60%,
  #285AEB 90%);
  outline-color: #a02d76;
}

@keyframes shake {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  30% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-15deg);
  }
}


@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

</style>