<template>
  <v-card rounded elevation="1"  class="mt-4">
    <v-card-text>
      <v-row class="yellow darken-2 rounded text-center mt-n6" no-gutters>
        <v-col cols="12" >
          <v-flex class="d-flex text-center align-baseline" >
            <v-spacer></v-spacer>
            <div class="subtitle-2 ma-n2 " >
              <span class="blue--text text--darken-2 font-weight-black" v-if="lang==='id'">{{computedKomoditiTypeSelected.description}}</span>
              <span class="blue--text text--darken-2 font-weight-black" v-if="lang==='en'">{{computedKomoditiTypeSelected.descriptionEn}}</span>
              <span class="ml-2 blue--text text--darken-2  font-weight-black">{{computedKomoditiFilteredTotal.toLocaleString()}}</span>
              <span class="ml-1" v-if="lookupFkomoditiType(computedKomoditiTypeSelected.id).fsatuanBean==0">kuintal/ekor</span>
              <span class="ml-1" v-else>{{ lookupFSatuan(lookupFkomoditiType(computedKomoditiTypeSelected.id).fsatuanBean).kode1 }}</span>

            </div>
            <v-spacer></v-spacer>
            <div>
              <v-btn color="red darken-3" small icon @click="closeKomoditi"><v-icon small>mdi-close</v-icon> </v-btn>
            </div>
          </v-flex>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-4">
        <v-col cols="12">
          <v-layout wrap justify-start class="d-flex">
            <div v-for="(item, i) in computedItemsFKomoditiType.filter(x => x.avatarImage != '' ) " :key="i" class="ma-1" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-if="lookupKomoditiFilteredTotal(item)">
                    <v-avatar
                            class="elevation-6"
                            v-bind="attrs"
                            v-on="on"
                            size="35" @click="showMarkerKomoditiByType(item)" >
                      <v-img
                          :src="lookupImageUrlVeryLow(item)"
                          width="50px"
                          height="=50px"
                      >
                        <div class="fill-height bottom-gradient"></div>
                      </v-img>
                    </v-avatar>
                  </div>
                </template>
                <span>{{item.description}} = {{ lookupKomoditiFilteredTotal(item).toLocaleString() }}</span>

                <span class="ml-1" v-if="lookupFkomoditiType(item.id).fsatuanBean==0">{{$t('kuintalEkor')}}</span>
                <span class="ml-1" v-else>{{ lookupFSatuan(lookupFkomoditiType(item.id).fsatuanBean).kode1 }}</span>
              </v-tooltip>
            </div>
          </v-layout>

        </v-col>

      </v-row>
    </v-card-text>
  </v-card>


</template>

<script>
import FileService from "@/services/apiservices/file-service";

export default {
  name: "ProfilDaerahDetilKomoditi",
  data(){
    return{
    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
      computedItemsFKomoditi(){
        return this.$store.state.potensi.itemsFKomoditi.filter(x => x.statusActive==true)
      },
      computedItemsFKomoditiType(){
        return this.$store.state.potensi.itemsFKomoditiType.filter(x => x.statusActive==true)
      },

    computedKomoditiShow:{
      get(){
        return this.$store.state.potensi.komoditiShow
      },
      set(value){
        this.$store.dispatch("potensi/updateKomoditiShow", value)
      }
    },
    computedKomoditiTypeSelected:{
      get(){
        return this.$store.state.potensi.komoditiTypeSelected
      },
      set(value){
        this.$store.dispatch("potensi/updateKomoditiTypeSelected", value)
      }
    },
    computedKomoditiFilteredTotal(){
      let total = 0
      this.$store.state.potensi.itemsFKomoditi.filter(x => x.fkomoditiTypeBean === this.computedKomoditiTypeSelected.id).forEach(item =>
        total += item.nilai1
      )
      return total
    }

  },
  methods:{
    closeKomoditi(){
      this.computedKomoditiShow = !this.computedKomoditiShow
      this.computedKomoditiTypeSelected = ''
    },
    showMarkerKomoditiByType(item){
      this.computedKomoditiTypeSelected = item
    },
    lookupKomoditiFilteredTotal(itemTypeBean){
      let total = 0
      this.$store.state.potensi.itemsFKomoditi.filter(x => x.fkomoditiTypeBean === itemTypeBean.id).forEach(item =>
          total += item.nilai1
      )
      return total
    },
    lookupFkomoditiType (fkomoditiTypeBean) {
      // console.log(fkomoditiTypeBean)
      // console.log(JSON.stringify(this.$store.state.potensi.itemsFKomoditiType))
      const str = this.$store.state.potensi.itemsFKomoditiType.filter(x => x.id==fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFSatuan (fsatuanBean) {
      const str = this.$store.state.potensi.itemsFSatuan.filter(x => x.id===fsatuanBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupImageUrlVeryLow(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  },
  mounted() {
  }
}
</script>

<style scoped>

</style>