<template>
  <div class="fill-height">
    <ProfilDaerahDetil
        jenis="DERAH"
    ></ProfilDaerahDetil>
  </div>
</template>

<script>
import ProfilDaerahDetil from "@/components/public_daerah_detil/ProfilDaerahDetil";

export default {
  name: "ProfilDaerahView",
  components:{
    ProfilDaerahDetil,
  }
}
</script>

<style scoped>

</style>