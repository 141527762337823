<template>
  <div>
    <apexchart type="donut" :options="chartOptions" :series="computedSeries"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "RealiasiChartDonut",
  components: {
    apexchart: VueApexCharts,
  },
  data: function() {
    return {

      labels: ['data', 'data', 'data', 'data', 'data'],
      series: [44, 55, 41, 17, 15],

    }
  },
  computed:{
    computedLabels(){
      return this.lookupPdrbLabels()
    },
    computedSeries(){
      return this.lookupPdrbSeries()
    },
    chartOptions() {
       let myChartOptions= {
          chart: {
             width: 280,
             type: 'donut',
           },
           plotOptions: {
             pie: {
               startAngle: -90,
               endAngle: 270
             },

           },
           dataLabels: {
             enabled: true
           },
           labels: this.computedLabels,
           fill: {
             type: 'gradient',
           },
           legend: {
             formatter: function (val, opts) {
               // return val + " - " + opts.w.globals.series[opts.seriesIndex]

               let value = opts.w.globals.series[opts.seriesIndex]
               let valueStr = ""
               if (value/1000000000000 >1) {
                 valueStr = `${parseFloat(value / 1000000000000).toFixed(2) } Triliun`
               }else if(value/1000000000>1){
                 valueStr = `${parseFloat(value / 1000000000).toFixed(2) } Miliar`
               }else if(value/1000000>1){
                 valueStr = `${parseFloat(value / 1000000).toFixed(2) } Juta`
               }else if(value/1000>1){
                 valueStr = `${parseFloat(value / 1000).toFixed(2) } Ribu`
               }else{
                 valueStr = value.toString()
               }

               return val + " - " + valueStr
             },
             position: 'right'
           },
           title: {
             text: ''
           },
          //responsive (seperti semacam tidak kepakai) panjang dan lebar menyesuaikan dengan lebar dialog yang kita sediakan
           responsive: [{
             // breakpoint: 380,
             options: {
               chart: {
                 width: 200,
                 type: 'donut',
               },
               legend: {
                 position: 'bottom',
               },

             }
           }]
         }
       return myChartOptions
    },

    computedItemsDayaDukungType() {
      return this.$store.state.potensi.itemsFDayaDukungType
    },
    computedItemsDayaDukung() {
      return this.$store.state.potensi.itemsFDayaDukung
    },

  },
  methods:{
    lookupFDayaDukungTypeByDesc (desc) {
      const str = this.computedItemsDayaDukungType.filter(x => x.description.toUpperCase().includes(desc.trim().toUpperCase()))
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupPdrbSeries(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("Realisasi")

      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.nilai1 < b.nilai1) ? 1: -1 )
      if (list.length >0) {
        let series = []
        list.forEach(item =>{
              if (! item.description.trim().toUpperCase().includes("PRODUK DOMESTIK REGIONAL BRUTO")){
                series.push(item.nilai1)
              }
            }
        )
        // console.log(JSON.stringify(series))
        return series
      }else {
        return []
      }
    },

    lookupPdrbLabels(){
      const typeBean = this.lookupFDayaDukungTypeByDesc("Realisasi")

      const list = this.computedItemsDayaDukung.filter(x => x.fdayaDukungTypeBean==typeBean.id).sort((a,b) => (a.nilai1 < b.nilai1) ? 1: -1 )
      if (list.length >0) {
        let labels = []
        list.forEach(item =>{
              if (! item.description.trim().toUpperCase().includes("PRODUK DOMESTIK REGIONAL BRUTO")){
                labels.push(item.description)
              }
            }
        )
        // console.log(JSON.stringify(series))
        return labels
      }else {
        return []
      }
    },
    formatNumberString(value){
      let valueStr = ""
      if (value/1000000000 >1) {
        valueStr = `${parseFloat(value / 1000000000).toFixed(2) } Miliar`
      }else if(value/1000000>1){
        valueStr = `${parseFloat(value / 1000000).toFixed(2) } Juta`
      }else if(value/1000>1){
        valueStr = `${parseFloat(value / 1000).toFixed(2) } Ribu`
      }else{
        valueStr = value.toString()
      }
      return valueStr
    },

  }

}
</script>

<style scoped>

</style>