<template>

  <l-map
      :center="currentMarker.coordinates"
      :zoom="zoom"
      :max-zoom="maxZoom"
      ref="map"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      style="z-index: 0; height: 800px; "
      :options="{scrollWheelZoom: false}"
      class="rounded-lg"
  >
    <l-tile-layer
        :url="url"
        attribution="OpenStreetMap"
    ></l-tile-layer>

    <l-control-layers position="topright"></l-control-layers>
    <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"/>

    <l-marker
        :key="currentMarker.id"
        :lat-lng="currentMarker.coordinates"
        ref="marker"
    >
      <l-popup>
        {{itemModified.description}}
      </l-popup>
      <l-icon
          :icon-size="currentMarker.iconSize"
          :icon-url="currentMarker.iconUrl"
      />
    </l-marker>


    <l-marker
        v-for="marker in computedMarkers "
        :key="marker.id"
        :lat-lng="marker.coordinates"
    >
      <l-tooltip class="rounded-lg">
        <div class="font-weight-bold">
          {{ lookupFPotensi(marker.id).kode2 }}
        </div>
        <div>
          {{ lookupFSubArea(lookupFPotensi(marker.id).fsubAreaBean).description }}
        </div>
      </l-tooltip>
      <l-icon
          :icon-size="marker.iconSize"
          :icon-anchor="marker.iconAnchor"
          :icon-url="marker.iconUrl"
      />

      <l-popup>
        <v-flex class="d-flex caption font-weight-bold">
          <v-icon x-small class="mr-1">mdi-tag</v-icon> {{ lookupFSectorType(lookupFPotensi(marker.id).fsectorTypeBean).description }}
        </v-flex>
        <v-flex class="d-flex">
          {{ lookupFPotensi(marker.id).kode2 }}
        </v-flex>
        <v-flex class="mt-1" v-if="lookupFPotensi(marker.id).district !==undefined">
          <div class="caption font-weight-bold ">
            {{ lookupFPotensi(marker.id).district }}
            ,
            {{ lookupFPotensi(marker.id).city }}
          </div>
          <div class="d-flex align-baseline">
              <span v-if="lookupFPotensi(marker.id).remarkBool2">
                <v-icon x-small color="orange">mdi-map</v-icon>
                <span class="caption ml-1">{{$t('terpetakan')}}</span>
              </span>
          </div>
        </v-flex>

        <v-flex class="mt-2 d-flex">
          <router-link style="text-decoration: none" :to="`/public-potensi-detail/` + linkNeated(marker.id.toString())">
            <v-btn color="blue lighten-2" dark x-small >
              <div class="caption align-baseline">
                <v-icon x-small>mdi-tag</v-icon>
                {{$t('lihatDetail')}}
              </div>
            </v-btn>
          </router-link>

          <v-spacer></v-spacer>
          <v-btn @click="openGoogleMap(lookupFPotensi(marker.id))" x-small plain>
            <v-icon small color="orange">mdi-map-marker</v-icon>
            Google
          </v-btn>

        </v-flex>

      </l-popup>
    </l-marker>

    <l-marker
        v-for="marker in computedInfraMarkers "
        :key="marker.id"
        :lat-lng="marker.coordinates"
    >
      <l-tooltip class="rounded-lg">
        <div class="font-weight-bold">
          {{ lookupFInfrastructure(marker.id).description }}
        </div>
        <div>
          {{ lookupFSubArea(lookupFInfrastructure(marker.id).fsubAreaBean).description }}
        </div>
      </l-tooltip>
      <l-icon
          :icon-size="marker.iconSize"
          :icon-url="marker.iconUrl"
      />
      <l-popup>
        <v-flex class="d-flex" >
          {{ lookupFInfrastructure(marker.id).description }}
        </v-flex>
        <v-flex class="mt-1" v-if="lookupFInfrastructure(marker.id).district !==undefined">
          <div class="caption font-weight-light">
            {{ lookupFInfrastructure(marker.id).district }}
          </div>
        </v-flex>
        <v-flex class="mt-2 d-flex" v-if="lookupFInfrastructure(marker.id).avatarImage !== '' ">
          <div v-if="false">
            {{lookupFInfrastructure(marker.id)}}
          </div>
          <v-img
              class="rounded-lg"
              :lazy-src="lookupImageUrlVeryLow(lookupFInfrastructure(marker.id))"
              :src="lookupImageUrlVeryLow(lookupFInfrastructure(marker.id))"
              height="300px"
              width="300px"
          >
          </v-img>
        </v-flex>
        <div v-if="lookupFInfrastructureType(lookupFInfrastructure(marker.id).finfrastructureTypeBean).description.toUpperCase().includes('PELABUHAN')">
          <v-data-table
              :headers="headers"
              :items="getDataItems(lookupFInfrastructure(marker.id))"
              hide-default-header
              hide-default-footer
              dense
          ></v-data-table>
        </div>

        <v-flex class="mt-2 d-flex">
          <v-spacer></v-spacer>
          <v-btn @click="openGoogleMap(lookupFInfrastructure(marker.id))" x-small plain>
            <v-icon small color="orange">mdi-map-marker</v-icon>
            Google
          </v-btn>
        </v-flex>
      </l-popup>
    </l-marker>

    <l-marker
        v-for="marker in computedKomoditiMarkers "
        :key="marker.id"
        :lat-lng="marker.coordinates"
    >
      <l-tooltip class="rounded-lg">
        <div>
          {{ $t('kecamatan') }} <span class="font-weight-bold">{{ lookupFKomoditi(marker.id).district }}</span>
        </div>
        <span class="font-weight-bold">{{ lookupFKomoditi(marker.id).nilai1.toLocaleString() }} </span>
        <span class="ml-1" v-if="lookupFkomoditiType(computedKomoditiTypeSelected.id).fsatuanBean==0">{{$t('kuintalEkor')}}</span>
        <span class="ml-1" v-else>{{ lookupFSatuan(lookupFkomoditiType(computedKomoditiTypeSelected.id).fsatuanBean).kode1 }}</span>
      </l-tooltip>

      <l-icon
          :icon-size="marker.iconSize"
          :icon-url="marker.iconUrl"
      />
      <l-popup>
        <div class="font-weight-bold">
          {{ lookupFKomoditi(marker.id).description }}
        </div>
        <v-flex class="mt-1" v-if="lookupFKomoditi(marker.id).district !==undefined">
          <div class="caption font-weight-light">
            {{$t('kecamatan')}} <span class="font-weight-bold">{{ lookupFKomoditi(marker.id).district }}</span>
          </div>
        </v-flex>
        <div>
          <span class="font-weight-bold">{{ lookupFKomoditi(marker.id).nilai1.toLocaleString() }} </span>
          <span class="ml-1" v-if="lookupFkomoditiType(computedKomoditiTypeSelected.id).fsatuanBean==0">{{$t('kuintalEkor')}}</span>
          <span class="ml-1" v-else>{{ lookupFSatuan(lookupFkomoditiType(computedKomoditiTypeSelected.id).fsatuanBean).kode1 }}</span>

        </div>

        <v-flex class="mt-2 d-flex">
          <div class="d-flex align-baseline mx-1">
            <v-icon x-small>mdi-calendar-range</v-icon>
            <span class="caption ml-1">BPS {{ lookupFKomoditi(marker.id).tahun }}</span>
          </div>


          <v-spacer></v-spacer>
          <v-btn @click="openGoogleMap(lookupFKomoditi(marker.id))" x-small plain class="mx-1">
            <v-icon small color="orange">mdi-map-marker</v-icon>
            Google
          </v-btn>
        </v-flex>

      </l-popup>

    </l-marker>

    <l-geo-json
        v-for="(item, index) in computedItemsSpaMainGeojson" :key="index"
        :geojson="item.data"
        :options="options"
        :options-style="styleFunction"
    >
    </l-geo-json>

    <l-control-polyline-measure :options="{ showUnitControl: true }" position="topleft"/>


  </l-map>

</template>

<script>
import { Icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup, LTooltip,
  LControlLayers,
  LGeoJson
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';

import FileService from "../../services/apiservices/file-service";
import FAreaService from "@/services/apiservices/f-area-service";
import jsondata from "@/assets/geojson/kpi.json";


// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "ProfileDaerahDetilMap",
  props:{
    itemModified: Object,
    itemsFile:[],
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup, LTooltip,
    LControlLayers,
    LControlPolylineMeasure,
    LGeoJson
  },
  data () {
    return {
      // itemModified: '',

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // center: [ -2.692921, 111.634003 ],
      zoom: 9,
      maxZoom: 18,
      // centered: { id: 0, coordinates: [-7.250445, 112.768845]},
      centered: { id: 0, coordinates: [0, 0]},

      currentMarker:
          {
            id: 1,
            coordinates: [0,0],
            // coordinates: [-7.4220966,112.8667491],
            // iconSize: [35, 55],
          },
      fPotensies:[],
      fInfrastructure:[],

      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      ],
      headers: [
        { text: 'Judul', value: 'title', width:'25%' },
        { text: 'Nilai', value: 'value', width:'50%' },
      ],

      itemSpaMainGeojson: [],
      enableTooltip: true,

    }
  },
  watch:{
    computedBandaraShow: function (){
      this.reloadFInfrastructure()
    },
    computedPelabuhanShow: function (){
      this.reloadFInfrastructure()
    },
    computedPendidikanShow: function (){
      this.reloadFInfrastructure()
    },
    computedHotelShow: function (){
      this.reloadFInfrastructure()
    },
    computedRumahSakitShow: function (){
      this.reloadFInfrastructure()
    },
    computedWisataShow: function (){
      this.reloadFInfrastructure()
    },
    computedPasarShow: function (){
      this.reloadFInfrastructure()
    },
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },

    // computedCurrentMarker: function (){
    //   this.setCentered()
    // }

  },

  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },

    computedCurrentMarker(){

      // console.log(JSON.stringify(this.itemModified.lat))
      // console.log(this.itemModified.markerImage1)

      let marker = this.currentMarker

      // if (this.itemModified.lat && this.itemModified.lon){
      //
      //   const markerImage1 = this.itemModified.markerImage1
      //
      //   // iconUrl: './assets/images/jagung.png',
      //
      //   if (this.lookupImageMarkerUrl(markerImage1)){
      //     marker = {id: this.itemModified.id, coordinates: [this.itemModified.lat, this.itemModified.lon],
      //       iconUrl: this.lookupImageMarkerUrl(markerImage1),
      //       iconSize: [80, 80],
      //     }
      //   }else {
      //     marker = {id: 1, coordinates: [this.itemModified.lat, this.itemModified.lon]}
      //   }
      //
      // }

      // if (this.itemModified.lat !=0 && this.itemModified.lon !=0) {
      //   this.$nextTick(() => {
      //     this.zoom = 10
      //     this.centered = marker
      //     this.$refs.marker.mapObject.openPopup()
      //   })
      // }


      return marker
    },

    computedMarkers(){
      let markers = []

      this.$store.state.potensi.itemsFPotensi.forEach( item =>{
        if (item.lat !==0 && item.lon !==0){
          let marker = ''

          const sectorType = this.lookupFSectorType(item.fsectorTypeBean)
          const sectorTypeMarker1 = sectorType.markerImage1

          if (! this.lookupImageMarkerUrl(sectorTypeMarker1).includes('pasar')){
            marker = {id: item.id, coordinates: [item.lat, item.lon],
              iconUrl: this.lookupImageMarkerUrl(sectorTypeMarker1),
              iconSize: [45, 65],
            }
          }else {
            marker = {id: item.id, coordinates: [item.lat, item.lon],
              iconSize: [45, 65],
            }
          }

          markers.push(marker)
        }
      })
      return markers
    },
    computedPotensies(){
      return this.$store.state.potensi.itemsFPotensi
    },
    computedItemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType
    },

    computedItemsFInfrastructure(){
      return this.$store.state.potensi.itemsFInfrastructure
    },
    computedItemsFInfrastructureType(){
      return this.$store.state.potensi.itemsFInfrastructureType
    },
    computedItemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType
    },
    computedItemsFKomoditi(){
      return this.$store.state.potensi.itemsFKomoditi
    },

    computedItemsFSubArea(){
      return this.$store.state.potensi.itemsFSubArea
    },

    computedInfraMarkers(){
      let markers = []

      if (this.$store.state.potensi.itemsFInfrastructureFiltered !==undefined){
        this.$store.state.potensi.itemsFInfrastructureFiltered.forEach( item =>{

          if (item.lat !==0 && item.lon !==0){

              const infrastructureType = this.lookupFInfrastructureType(item.finfrastructureTypeBean)
              const infrastructureTypeMarker1 = infrastructureType.markerImage1
              let marker = ''
              marker = {id: item.id, coordinates: [item.lat, item.lon],
                iconUrl: this.lookupImageMarkerUrl(infrastructureTypeMarker1),
                iconSize: [33, 45],
              }

              markers.push(marker)

          }
        })
      }

      return markers
    },

    computedBandaraShow:{
      get(){
        return this.$store.state.potensi.bandaraShow
      },
      set(value){
        this.$store.dispatch("potensi/updateBandaraShow", value)
      }
    },
    computedPelabuhanShow:{
      get(){
        return this.$store.state.potensi.pelabuhanShow
      },
      set(value){
        this.$store.dispatch("potensi/updatePelabuhanShow", value)
      }
    },
    computedPendidikanShow:{
      get(){
        return this.$store.state.potensi.pendidikanShow
      },
      set(value){
        this.$store.dispatch("potensi/updatePendidikanShow", value)
      }
    },
    computedHotelShow: {
      get(){
        return this.$store.state.potensi.hotelShow
      },
      set(value){
        this.$store.dispatch("potensi/updateHotelShow", value)
      }
    },
    computedRumahSakitShow:{
      get(){
        return this.$store.state.potensi.rumahSakitShow
      },
      set(value){
        this.$store.dispatch("potensi/updateRumahSakitShow", value)
      }
    },
    computedWisataShow:{
      get(){
        return this.$store.state.potensi.wisataShow
      },
      set(value){
        this.$store.dispatch("potensi/updateWisataShow", value)
      }
    },
    computedPasarShow:{
      get(){
        return this.$store.state.potensi.pasarShow
      },
      set(value){
        this.$store.dispatch("potensi/updatePasarShow", value)
      }
    },
    computedKomoditiTypeSelected:{
      get(){
        return this.$store.state.potensi.komoditiTypeSelected
      },
      set(value){
        this.$store.dispatch("potensi/updateKomoditiTypeSelected", value)
      }
    },

    computedKomoditiMarkers(){
      // return this.$store.state.potensi.itemsFKomoditi.filter(x => x.fkomoditiTypeBean === this.computedKomoditiTypeSelected.id)

      let markers = []

      this.$store.state.potensi.itemsFKomoditi.filter(x => x.fkomoditiTypeBean === this.computedKomoditiTypeSelected.id).forEach( item =>{
        const subArea =  this.lookupFSubArea(item.fsubAreaBean)
        if (item.lat ==0 || item.lon ==0){
          item.lat = parseFloat(subArea.lat)
          item.lon = parseFloat(subArea.lon)
        }

        item.district = subArea.description

        if (item.lat !==0 && item.lon !==0 && ! isNaN(item.lat) && ! isNaN(item.lon) ){
          let marker = ''

          const itemType = this.lookupFkomoditiType(item.fkomoditiTypeBean)
          const itemTypeMarker1 = itemType.markerImage1

          marker = {id: item.id, coordinates: [item.lat, item.lon],
            iconUrl: this.lookupImageMarkerUrl(itemTypeMarker1),
            iconSize: [50, 47],
          }
          markers.push(marker)
        }
      })

      // console.log(markers)

      return markers
    },

    computedItemsSpaMainGeojson(){
      return this.itemSpaMainGeojson
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },

    styleFunction() {

      let lineColor = "blue";
      let fillColor = "blue";
      let opacity = 0.8;

      return () => {
        // console.log(value.fillColor)

        return {
          weight: 1,
          color: lineColor,
          opacity: opacity,
          fillColor: fillColor,
          fillOpacity: 0.4
        };
      };

    },

    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
            "<div style='font-weight: bold;color: blue'>Kawasan Peruntukan Industri" +
            "</div>" +
            "<div style=''><span style='font-weight: bold'>" +
            feature.properties.KECAMATAN.replace("KECAMATAN","") +
            "</span>, "+
            feature.properties.KABUPATEN +
            "</div>" +
            "<div>Luas: <span style='font-weight: bold'>" +
            parseInt(feature.properties.Luas) +
            "</span> Ha</div>" +
            // "<div style='font-size: smaller;'>Lat: " +
            // feature.properties.Lat +
            // ", Lon: " +
            // feature.properties.Long +
            "" +
            "</div>",
            { permanent: false, sticky: true }
        );

      };
    }

  },
  methods: {
    getDataItems(item){

      let items =[]

      if (item.tahun > 0) {
        items.push({title: 'Data Tahun', value: item.tahun})
      }
      if (item.phone != '') {
        items.push({title: 'Phone', value: item.phone})
      }
      if (item.email != '') {
        items.push({title: 'Email', value: item.email})
      }

      if (item.remark1 != ''){
        items.push({ title: 'Fasilitas',  value: item.remark1 })
      }
      // items.push({ title: 'xx',  value: this.itemModified.remark2 })
      if (item.remark3 != '') {
        items.push({title: 'Harga Sewa', value: item.remark3})
      }
      if (item.remark4 != '') {
        items.push({title: 'Aset Kerja', value: item.remark4})
      }
      if (item.remark5 != '') {
        items.push({title: 'Ukuran Kap', value: item.remark5})
      }

      if (item.remarkInt1 != 0) {
        items.push({title: 'Jml Dermaga', value: item.remarkInt1})
      }
      // items.push({ title: 'xx',  value: this.itemModified.remarkInt2 })

      if (item.remarkDouble1 != 0.0) {
        items.push({title: 'Kedalaman', value: item.remarkDouble1})
      }
      if (item.remarkDouble2 != 0.0) {
        items.push({title: 'Tonase/DWT', value: item.remarkDouble2})
      }

      return items
    },

    setCentered(){

      // const newMarker =
      //     {
      //       id: 1,
      //       coordinates: [this.itemModified.lat, this.itemModified.lon],
      //       iconSize: [45, 65],
      //       // iconAnchor: [16, 37]
      //     }
      //
      // this.$nextTick(() => {
      //   this.centered = newMarker
      //   this.currentMarker = newMarker
      //   this.zoom = 10
      //   this.$refs.marker.mapObject.openPopup()
      //
      // })

    },

    streetmap(){
      this.maxZoom =18
      this.url= 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    satelite(){
      this.maxZoom =17
      this.url = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log(this.markers)
    },
    centerUpdated (center) {
      this.center = center;
    },
    markerClicked(value){
      this.retrieveFiles(value)

    },
    retrieveFiles(id){
      console.log(id)
    },
    innerClick() {
      alert("Click!");
    },
    fetchParent(){
    },

    reloadFInfrastructure(){
      let listInfrastucture = []
      if (this.computedItemsFInfrastructure !=undefined){
        this.computedItemsFInfrastructure.forEach(item => {

          // console.log(JSON.stringify(this.computedItemsFInfrastructure))

          let show = false
          const infrastructureType = this.lookupFInfrastructureType(item.finfrastructureTypeBean)
          if (this.computedBandaraShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("BANDARA")){
              show = true
            }
          }
          if (this.computedPelabuhanShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("PELABUHAN")){
              show = true
            }
          }
          if (this.computedHotelShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("HOTEL")){
              show = true
            }
          }
          if (this.computedPendidikanShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("PENDIDIKAN")){
              show = true
            }
          }
          if (this.computedRumahSakitShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("RUMAH SAKIT")){
              show = true
            }
          }
          if (this.computedWisataShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("WISATA")){
              show = true
            }
          }
          if (this.computedPasarShow ==true){
            if (infrastructureType.description.toUpperCase().trim().includes("PASAR")){
              show = true
            }
          }

          if (show){
              listInfrastucture.push(item)
            }
        })
      }

      this.fInfrastructure = listInfrastucture
    },

    lookupFPotensi (fpotensiBean) {
      const items = this.computedPotensies.filter(x => x.id==fpotensiBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },
    lookupFSectorType (fsectorTypeBean) {
      const str = this.computedItemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFInfrastructure (finfrastructureBean) {
      const str = this.computedItemsFInfrastructure.filter(x => x.id===finfrastructureBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFInfrastructureType (finfrastructureTypeBean) {
      const str = this.computedItemsFInfrastructureType.filter(x => x.id===finfrastructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFkomoditiType (fkomoditiTypeBean) {
      const str = this.computedItemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSatuan (fsatuanBean) {
      const str = this.$store.state.potensi.itemsFSatuan.filter(x => x.id===fsatuanBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFKomoditi (fkomoditiBean) {
      const str = this.computedItemsFKomoditi.filter(x => x.id===fkomoditiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    
    lookupFSubArea (fsubAreaBean) {
      const str = this.computedItemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlVeryLow(item){
      // console.log(JSON.stringify(item))
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    lookupImageUrlLazy(){
      return './assets/images/pasar.png'
    },
    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/pasar.png'
      }else {
        return FileService.image_url_ori(markerImage)
      }
    },
    openGoogleMap(item) {

      const urlSuffix = `${item.lat},${item.lon}&z=15`
      window.open(
          "https://maps.google.com/maps?q=" + urlSuffix,
          "_blank"
      );
    },
    lookupGeoJsonColor(value){
      let theColor = 'blue'
      value.toString()

      // if (value.toUpperCase().includes('PERMUKIMAN')){
      //   theColor = '#c9791c'
      // }else {
      //   theColor = 'white'
      // }
      return theColor
    },

    async fetchKpi(value){
      this.itemSpaMainGeojson = []

      let newCity = value.toUpperCase().trim()
      newCity = newCity.replace("KOTAMADYA", "")
      newCity = newCity.replace("KOTA", "")
      newCity = newCity.replace("KABUPATEN", "")

      // const asyncComp = defineAsyncComponent(() =>
      //     import('@/assets/geojson/kpi.json')
      // )

      let newFeatures = []
      await jsondata.features.filter( x => x.properties.KABUPATEN.toUpperCase().includes(`${newCity}`)).forEach( x => {
        newFeatures.push(x)
      })
      const newJson = {
        type: "FeatureCollection",
        crs: jsondata.crs,
        features: newFeatures
      }
      const geojsonResponse = newJson
      // const geojsonResponse = jsondata

      this.itemSpaMainGeojson.splice(1,1, {id: 1, data: geojsonResponse, lineColor: 'orange', fillColor: 'orange'} )

    },

  },

  mounted() {
    const paramId = this.$route.params.id
    // console.log(paramId)
    //
    FAreaService.getFAreaById(paramId).then(
        response =>{
          // console.log(response)
          this.centered = { id: 0, coordinates: [response.data.lat, response.data.lon]}

          const markerImage1 = this.itemModified.markerImage1
          if (! this.lookupImageMarkerUrl(markerImage1).includes('pasar')){
            this.currentMarker ={
              id: 1,
              coordinates: [response.data.lat, response.data.lon],
              iconUrl: this.lookupImageMarkerUrl(markerImage1),
              iconSize: [80, 80]
            }
          }else {
            this.currentMarker ={
              id: 1,
              coordinates: [response.data.lat, response.data.lon]
            }
          }

          this.fetchKpi(response.data.description)
          // console.log(JSON.stringify(response.data))

          this.$nextTick(() => {
            this.zoom = 10
            // this.centered = marker
            this.centered = { id: 0, coordinates: [response.data.lat, response.data.lon]}
            this.$refs.marker.mapObject.openPopup()
          })

        }
    )

  }
}
</script>

<style scoped>

</style>